import React from "react";

const Testimonial = () => {
  return (
    <>
      <div className="container">
        <div className="about-us">
          <div className="about-us-wrapper test">
            <h1>
              Meet SaaS Leaders with <span>a <br/> disruptive vision</span>
            </h1>
            <p>
              Here are some of the 100s of testaments to zensei's winning
              approach to acquiring exceptional SaaS talents
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
