import React from 'react'
import leader from '../../images/leader.png'
import { Link } from 'react-router-dom'

const ServiceLeadership = () => {
  return (
  <>
  <div className='service-leader' id="leadership">
    <div className='container'>
  <div className='service-leader-wrapper'>
  <div className='service-leader-wrapper-left'>
    <h1>Leadership SaaS <br/> Tech Hiring</h1>
    <p>Unlock leadership excellence and get the north star <br/> your vision needs. Our leadership acquisition creates <br/> the invincible symphony that propels your<br/> enterprise to greatness </p>
    <ul>
        <li>Unparalleled due diligence</li>
        <li>End to end acquisition consulting</li>
    </ul>
    <Link to="/contact"><button className='button-roles'>Know More</button></Link>
    </div>
    <div className='service-leader-wrapper-left'>
        <img src={leader} alt=''/>
    </div>

    </div>
    </div>
  </div>
  </>
  )
}

export default ServiceLeadership