import React from "react";
import CountUp from "react-countup";

const HomeCounter = () => {
  return (
    <>
      <div className="container ">
        <div className="homeCounter">
          <div className="counter-wrapper">
            <CountUp
              start={0}
              end={10}
              className="saas"
              duration={3}
              suffix="+"
            ></CountUp>
            <p>
              SaaS Tech <br /> Hiring Experience
            </p>
          </div>
          <div className="counter-wrapper">
            <CountUp
              start={0}
              end={80}
              className="saasF"
              duration={3}
              suffix="%"
            ></CountUp>
            <p>
              Onboarding <br />
              Success Rate
            </p>
          </div>
          <div className="counter-wrapper">
            <CountUp
              start={0}
              end={25}
              className="saas"
              duration={3}
              suffix="+"
            ></CountUp>
            <p>
              SaaS <br /> Startups Served
            </p>
          </div>
          <div className="counter-wrapper">
            <CountUp
              start={0}
              end={50}
              className="saasF"
              duration={3}
              suffix="+"
            ></CountUp>
            <p>
              Success <br /> Stories
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCounter;
