// import React from "react";
// import { TbMathGreater } from "react-icons/tb";

// const BlogCardDetails = () => {
//   return (
//     <>
//       <div className="container">
//         <div className="blog-card-details">
//           <div className="blog-card-details-route">
//             <p>Blog</p>
//             <TbMathGreater />
//             <h6>HRefresh</h6>
//           </div>
//           <div className="blog-card-details-content">
//             <img
//               src="https://assets-global.website-files.com/62e8d2ea218fb738666892cd/6501bc19daedd74895d33f53_Tech%20Jargons%20Simplified-p-800.webp"
//               alt=""
//             />
//             <h1>Let's understand the Tech roles:</h1>
//             <h3>Front-end developer</h3>
//             <p>
//               Think of a front-end developer as the architect who designs the
//               exterior and layout of a building. They use languages like HTML,
//               CSS, and JavaScript to create the user-friendly interface you see
//               when you visit a website or use an app.
//             </p>
//             <h3>Front-end developer</h3>
//             <p>
//               Think of a front-end developer as the architect who designs the
//               exterior and layout of a building. They use languages like HTML,
//               CSS, and JavaScript to create the user-friendly interface you see
//               when you visit a website or use an app.
//             </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default BlogCardDetails;

// BlogCardDetails.jsx

import React, { useEffect, useState } from "react";
import { TbMathGreater } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";

const BlogCardDetails = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    // Fetch blog data based on the blog ID
    fetch(`https://admin.zensei.in/api/blogs/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data); // Assuming the API response structure matches your expectations
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [id]);

  if (!blogData) {
    // You may want to show a loading indicator while fetching data
    return <div>Loading...</div>;
  }
  const firstImage = blogData.blogs.details[0]?.images;
  return (
    <>
      <div className="container">
        <div className="blog-card-details">
          <div className="blog-card-details-route">
            <p>Blog</p>
            <TbMathGreater />
            <h6 className="capital">{blogData.blogs.category}</h6>
          </div>
          <div className="blog-card-details-content">
            {firstImage && (
              <img src={`${"https://admin.zensei.in/"}${firstImage}`} alt="" />
            )}
            <h1>{blogData.blogs.heading}</h1>
            {blogData.blogs.details.map((detail, index) => (
              <div key={index}>
                <h3>{detail.sub_heading}</h3>
                <p dangerouslySetInnerHTML={{ __html: detail.paragraph }} />
              </div>
            ))}
             <div className="bloglistcategory card-button-blog">
          <Link to="/blogs">
            <button className="blog-button">← Back to all blog articles</button>
          </Link>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCardDetails;
