import React from "react";
import about from '../../images/about.png'

const AboutHead = () => {
  return (
    <>
      <div className="container">
        <div className="about-us">
          <div className="about-us-wrapper">
            <h1>Redefining SaaS Tech hiring</h1>
            <p>
            Leveraging decades of successful talent placements, Zensei collaborates with forward-thinking SaaS startups to drive their growth. Our strategies have been perfected by addressing and resolving key SaaS challenges repeatedly.
            </p>
            <img
              src={about}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHead;
