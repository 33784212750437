import React from "react";
import Home from "./components/home/Home";
import Topbar from "./Topbar";
import { Routes, Route } from "react-router-dom";
import BottomBar from "./components/BottomBar";
import Footer from "./components/Footer";
import Contact from "./components/contact/Contact";
import Aboutus from "./components/about/Aboutus";
import Testimonials from "./components/testimonials/Testimonials";
import Service from "./components/service/Service";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Blog from "./components/blog/Blog";
import BlogCardDetails from "./components/blog/BlogCardDetails";
import BlogListCategory from "./components/blog/BlogListCategory";

const App = () => {
  return (
    <>
      <Topbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/testimonial" element={<Testimonials />} />
        <Route path="/service" element={<Service />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog-details/:id" element={<BlogCardDetails />} />
        <Route path="/blog-category/:category" element={<BlogListCategory />} />
      </Routes>
      <Footer />
      <BottomBar />
    </>
  );
};

export default App;
