import React from "react";
import high from "../../images/high.png";
import plane from "../../images/plane.png";
import service from "../../images/service.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import backend from "../../images/Backend Engineer.svg"
import frontend from "../../images/Frontend Engineer.svg"
import analytic from "../../images/Analytics Engineer.svg"
import datae from "../../images/Data Engineer.svg"
import datasc from "../../images/Data Scientist.svg"
import devops from "../../images/DevOps Engineer.svg"
import fullstack from "../../images/Fullstack Engineer.svg"
import mobile from "../../images/Mobile Engineer.svg"
import security from "../../images/Security Engineer.svg"
import teste from "../../images/Test Engineer.svg"
import ui from "../../images/UI-UX Designer.svg"
import product from "../../images/Product Manager.svg"
import Project from "../../images/Project Manager.svg"
import { Link } from "react-router-dom";




const ServiceExperience = () => {
  return (
    <>
      <div className="container" id="tech">
        <div className="home-experience service-experience">
          <div className="home-experience-text service-experience-left">
            <Tabs>
              <div className="service-button">
                <TabList>
                  <Tab> Overview</Tab>
                  <Tab>
                  
                   Roles we Hire For
                  </Tab>
                </TabList>
              </div>
              <TabPanel>
                <h1>
                  Mid and Senior SaaS <br /> Tech Hiring
                </h1>
                <p>
                  Service designed to align skillsets of Mid and Senior <br />{" "}
                  SaaS Tech professionals with crucial company KPIs,
                  <br /> mapped out to ensure disruptive solutions
                </p>
                <div className="home-experience-text-box">
                  <div>
                    <img src={high} alt="" width={40} />

                    <h4>
                      Data Driven
                      <br /> Processes
                    </h4>
                    <p>
                      Every decision we take is powered by countless data sets
                    </p>
                  </div>
                  <div>
                    <img src={plane} alt="" width={40} />

                    <h4>
                      Key Zensei Frame <br /> Metrics
                    </h4>
                    <p>
                      We align motivations, mobility, behavioral metrics and
                      more
                    </p>
                  </div>
                </div>
                <Link to="/contact"><button className="button1">Know More</button></Link>
              </TabPanel>
              <TabPanel>
              <h1>
                  Mid and Senior SaaS <br /> Tech Hiring
                </h1>
                {/* <p>
                  Service designed to align skillsets of Mid and Senior <br />{" "}
                  SaaS Tech professionals with crucial company KPIs,
                  <br /> mapped out to ensure disruptive solutions
                </p> */}
                <div className="dev-list">
                  <ol >
                    <li><img src={backend} alt=""/> Backend Engineer</li>
                    <li><img src={frontend} alt=""/>Frontend Engineer</li>
                    <li><img src={fullstack} alt=""/>Fullstack Engineer</li>
                    <li><img src={mobile} alt=""/>Mobile Engineer</li>
                    <li><img src={teste} alt=""/>Test Engineer</li>
                    <li><img src={devops} alt=""/>DevOps Engineer</li>
                    <li><img src={datae} alt=""/>Data Engineer</li>
                    <li><img src={datasc} alt=""/>Data Scientist</li>
                    <li><img src={product} alt=""/>Product Manager</li>
                    <li><img src={ui} alt=""/>UI/UX Designer</li>
                    <li><img src={Project} alt=""/>Project Manager</li>
                    <li><img src={security} alt=""/>Security Engineer</li>
                    <li><img src={analytic} alt=""/>Analytics Engineer</li>

                  </ol>
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <div className="home-experience-image">
            <img src={service} className="imge" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceExperience;

// import React from "react";
// import high from "../../images/high.png";
// import plane from "../../images/plane.png";
// import service from "../../images/service.png"

// const ServiceExperience = () => {
//   return (
//     <>
//       <div className="container">
//         <div className="home-experience service-experience">
//           <div className="home-experience-text service-experience-left">
//             <div className="service-button">
//               <button className="button1">Overview</button>
//               <button className="button-roles">Roles we Hire For</button>
//             </div>

//             <h1>
//               Mid and Senior SaaS <br /> Tech Hiring
//             </h1>
//             <p>
//               Service designed to align skillsets of Mid and Senior <br/> SaaS Tech
//               professionals with crucial company KPIs,<br/> mapped out to ensure
//               disruptive solutions
//             </p>
//             <div className="home-experience-text-box">
//               <div>
//               <img src={high} alt="" width={40}/>

//                 <h4>
//                   Data Driven
//                   <br /> Processes
//                 </h4>
//                 <p>Every decision we take is powered by countless data sets</p>
//               </div>
//               <div>
//               <img src={plane} alt="" width={40}/>

//                 <h4>
//                   Key Zensei Frame <br /> Metrics
//                 </h4>
//                 <p>
//                   We align motivations, mobility, behavioural metrics and more
//                 </p>
//               </div>
//             </div>
//             <button className="button1">Know More</button>
//           </div>
//           <div className="home-experience-image">
//             <img src={service} className="imge" alt="" />

//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ServiceExperience;
