import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import zenz from "./images/zense.png";

function Topbar() {
  return (
    <Navbar expand="lg" className="">
      <Container>
        <span className="navphone">
          <Link to="/">
            <img src={zenz} alt="" />
            {/* <p>
              Where SaaS start-ups meet <br /> exceptional talents
            </p>{" "} */}
          </Link>
        </span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <span className="navleft">
            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/service">Services</Link>
          </span>

          <span className="navCenter">
            <Link to="/">
              <img src={zenz} alt="" />
              {/* <p>
                Where SaaS start-ups meet <br />
                exceptional talents
              </p>{" "} */}
            </Link>
          </span>

          <span className="navRight">
            <Link to="/testimonial">Testimonials </Link>
            <Link to="/contact">
              {" "}
              <button>Let's Disrupt</button>
            </Link>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;
