import React from "react";
import bountique from "../../images/bountique.png";
import rightarrow from "../../images/right-arrow.png";
import { Link } from "react-router-dom";

const ServiceBoutique = () => {
  return (
    <>
      <div className="container">
        <div className="service-boutique">
          <div className="service-boutique-left">
            <h1>Customized SaaS Tech Hiring</h1>
            <p>
            Opt for personalized hiring solutions that precisely align with your company's tech needs, be it university preferences, specialized industry expertise, specific skill sets, desired locations, or flexibility and stability.
            </p>
            <ul>
              <li>
                <img src={rightarrow} alt="" />
                Access top-tier talent with our global presence
              </li>
              <li>
                <img src={rightarrow} alt="" />
                Pick from custom job profiles with niche skills
              </li>
              {/* <li>
                <img src={rightarrow} alt="" />
                Lorem ipsum dolor sit amet dolor sit
              </li> */}
            </ul>
            <Link to="/contact"><button className="button1">Know More</button></Link>
          </div>
          <div className="service-boutique-left">
            <img src={bountique} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBoutique;
