import React from "react";

const ServiceBanner = () => {
  return (
    <>
      <div className="container">
        <div className="about-us">
          <div className="about-us-wrapper test">
            <h1>
            Services calibrated and perfected <br/>to  <span>build SaaS legacies  </span>
            </h1>
            <p>
            Our offerings, are designed to directly tackle and resolve core challenges faced by SaaS startups and leaders.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBanner;
