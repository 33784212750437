import React, { useEffect } from 'react'
import AboutCompanies from './AboutCompanies'
import AboutHead from './AboutHead'
import AboutExperience from './AboutExperience'
import AboutPhilospy from './AboutPhilospy'
import AboutTesto from './AboutTesto'
import OurValues from './OurValues'
import AboutTeam from './AboutTeam'

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);
  return (
    <>
    <AboutHead/>
    <AboutCompanies/>
    <AboutExperience/>
    <AboutTesto/>
    <AboutTeam/>
    <AboutPhilospy/>
    <OurValues/>
    </>
  )
}

export default Aboutus