import React from "react";
import man from "../../images/man.png"
import { Link } from "react-router-dom";

const ServicePower = () => {
  return (
    <>
    
      <div className="container service-power-wrapper">
        <div className="service-power">
          <div className="service-power-left">
            <img src={man} alt="" />
          </div>
          <div className="service-power-left spacing-bottom">
            <h1>Ready to power your <br/> journey with</h1>
            <h5>the best of SaaS minds?</h5>
            <Link to="/contact"><button className="button-roles">Let’s get connected</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePower;
