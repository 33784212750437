import React, { useState, useEffect } from "react";
import { BsArrowDownRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch("https://admin.zensei.in/api/blogs")
      .then((response) => response.json())
      .then((data) => {
        // Assuming that the data structure matches your API response
        setBlogs(data.blogs);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const limitTextTo23Words = (text) => {
    const words = text.split(" ");
    const limitedWords = words.slice(0, 63);
    return limitedWords.join(" ");
  };
  return (
    <>
      <div className="container">
        <div className="blog-card">
          <div className="blog-card-tab">
            <Link to="/blogs">
              <button>All</button>
            </Link>
            <Link to="/blog-category/Product">
              <button>Product</button>
            </Link>
            <Link to="/blog-category/Tech">
              <button>Tech</button>
            </Link>
            <Link to="/blog-category/Design">
              <button>Design</button>
            </Link>
            <Link to="/blog-category/Industry News">
              <button>Industry News</button>
            </Link>
            <Link to="/blog-category/Success Stories">
              <button>Success Stories</button>
            </Link>
            <Link to="/blog-category/know us">
              <button>Know Us</button>
            </Link>
          </div>
          <div className="blog-multiple-card">
            {blogs.map((blog) => (
              <div key={blog.id} className="blog-multiple-card-wrapper">
                <div className="blog-multiple-card-wrapper-img">
                  <Link to={`/blog-details/${blog.id}`}>
                    {blog.details && blog.details[0] && (
                      <img
                        src={`${"https://admin.zensei.in/"}${
                          blog.details[0].images
                        }`} // Append the relative image path to the base URL
                        alt=""
                      />
                    )}
                  </Link>
                </div>

                <div className="blog-multiple-card-wrapper-content">
                  <h6 className="capital">{blog.category}</h6>
                  <Link to={`/blog-details/${blog.id}`}>
                    <div>
                      <h3>{blog.heading}</h3>
                      <span>
                        <BsArrowDownRight />
                      </span>
                    </div>
                  </Link>

                  {blog.details && blog.details[0] && (
                    <p  className="para-blogs"
                      dangerouslySetInnerHTML={{
                        __html: limitTextTo23Words(blog.details[0].paragraph),
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
