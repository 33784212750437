// import React from "react";
// import apollo from "../../images/apollo.jpeg";
// import ihx from "../../images/ihx.png";
// import infileot from "../../images/inf.png";
// import light from "../../images/light.png";
// import dynamo from "../../images/dynamo.png";
// import time from "../../images/time.png";
// import gnani from "../../images/gnani.png";
// import work from "../../images/work.png";
// import how from "../../images/how.png";
// import nex from "../../images/nex.png";
// import medisage from "../../images/medisage.png";
// import hiver from "../../images/hiver.jpeg";

// const AboutCompanies = () => {
//   return (<>
//   <div className="about-company">
//     <div className="container">
//       <div className="about-company-wrapper">
//         <div className="about-company-left">
//           <h1>
//             Companies fueled <br /> by the `<span>Zensei <br /> Frame</span>`
//           </h1>
//           <p>
//             Each of those logos come with stories of distinctive hiring
//             roadblocks that we overcame to help these disruptive organization
//             uncover additional market shares
//           </p>
//         </div>
//         <div className="about-company-right">
//           <img src={apollo} alt="" />
//           <img src={ihx} alt="" />
//           <img src={infileot} alt="" />
//           <img src={light} alt="" />
//            <img src={dynamo} alt="" />
//           <img src={time} alt="" />
//           <img src={gnani} alt="" />
//           <img src={work} alt="" />
//           <img src={how} alt="" />
//           <img src={nex} alt="" />
//            <img src={medisage} alt="" />
//           <img src={hiver} alt="" />
//         </div>
//       </div></div>
//     </div></>
//   );
// };

// export default AboutCompanies;

import React from "react";
import gajigesa from "../../images/gajigesa.png";
import light from "../../images/light.png";
import urban from "../../images/urban.svg";
import sen from "../../images/sen.png";
import elevetion from "../../images/elevetion.png";
import ihx from "../../images/ihx.png";
import mat from "../../images/mat.png";
import col from "../../images/col.jpeg";
import peak from "../../images/peak.webp";
import smartstaf from "../../images/smartstaf.svg";
import bes from "../../images/bes.png";
import nex from "../../images/nex.png";
import apollo from "../../images/ap.png";
import inf from "../../images/inf.png";
import dynamo from "../../images/dynamo.jpeg";
import gn from "../../images/gn.png";
import time from "../../images/time.png";
import work from "../../images/work.png";
import how from "../../images/how.png";
import hiver from "../../images/hi.png";
import medisage from "../../images/medisage.png";
import zen from "../../images/zen.png";
import ziroh from "../../images/ziroh.png";
import enty from "../../images/enty.png";
import deep from "../../images/deep.png";
import giga from "../../images/giga.png";

import Slider from "react-slick";

const AboutCompanies = () => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    speed: 500,
    slidesToShow: 3,

    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="about-company">
        <div className="container">
          <div className="about-company-wrapper">
            <div className="about-company-left">
              <h1>
                Companies powered
                <br /> by '<span>Zensei</span>'
              </h1>
              <p>
                Each of those logos come with stories of distinctive hiring
                roadblocks that we overcame to help these disruptive
                organization uncover additional market shares
              </p>
            </div>
            <div className="about-company-right-top">
              <p className="para-white center-about">Companies served </p>

              <div className="about-company-rights">
                <Slider {...settings}>
                  <div>
                    <img src={apollo} alt="" />
                  </div>
                  <div>
                    <img src={inf} alt="" />
                  </div>
                  <div>
                    <img src={dynamo} alt="" />
                  </div>
                  <div>
                    <img src={gn} alt="" />
                  </div>
                  <div>
                    <img src={time} alt="" />
                  </div>
                  <div>
                    <img src={work} alt="" />
                  </div>
                  <div>
                    <img src={how} alt="" />
                  </div>
                  <div>
                    <img src={hiver} alt="" />
                  </div>
                  <div>
                    <img src={medisage} alt="" />
                  </div>
                  <div>
                    <img src={zen} alt="" />
                  </div>
                  <div>
                    <img src={ziroh} alt="" />
                  </div>
                  <div>
                    <img src={smartstaf} alt="" />
                  </div>
                  <div>
                    <img src={ihx} alt="" />
                  </div>
                  <div>
                    <img src={gajigesa} alt="" />
                  </div>
                  <div>
                    <img src={urban} alt="" />
                  </div>
                  <div>
                    <img src={light} alt="" />
                  </div>
                  <div>
                    <img src={sen} alt="" />
                  </div>
                  <div>
                    <img src={col} alt="" />
                  </div>
                  <div>
                    <img src={giga} alt="" />
                  </div>{" "}
                  <div>
                    <img src={deep} alt="" />
                  </div>{" "}
                  <div>
                    <img src={enty} alt="" />
                  </div>
                </Slider>
              </div>
              <p className="para-white center-about">VCs </p>

              <div className="about-company-rights">
                <Slider {...settings}>
                  <div>
                    <img src={elevetion} alt="" />
                  </div>
                  <div>
                    <img src={peak} alt="" />
                  </div>
                  <div>
                    <img src={bes} alt="" />
                  </div>
                  <div>
                    <img src={nex} alt="" />
                  </div>
                  <div>
                    <img src={mat} alt="" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompanies;
