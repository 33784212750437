import React, { useEffect } from "react";
import HomeSaas from "./HomeSaas";
import HomeCounter from "./HomeCounter";
import HomeJourney from "./HomeJourney";
import HomeExperience from "./HomeExperience";
import HomeServices from "./HomeServices";
import HomeTestomonials from "./HomeTestomonials";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home_f">
        <HomeSaas />
        <HomeCounter />
      </div>
      <HomeJourney />
      <div className="home_f">
        <HomeExperience />
      </div>
      <HomeServices />
      <HomeTestomonials />
    </>
  );
};

export default Home;
