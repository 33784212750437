import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { BsTelephoneForward } from "react-icons/bs";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    message: "",
  });

  const handleSubmit = async () => {
    // Validate the form fields before submitting
    if (validateForm()) {
      try {
        const response = await fetch("https://admin.zensei.in/api/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          // Request was successful, you can handle the response here
          alert("Your Form send Successfully");
          console.log(formData, "Request successful");
          setFormData({
            name: "",
            email: "",
            phone: "",
            role: "",
            message: "",
          });
        } else {
          // Request failed, handle the error here
          console.error("Request failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      phone: "",
      role: "",
      message: "",
    };

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Email is not in a valid format";
      valid = false;
    }

    if (formData.phone.trim() === "") {
      newErrors.phone = "Phone number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
      valid = false;
    }

    if (formData.role.trim() === "") {
      newErrors.role = "Role is required";
      valid = false;
    }

    if (formData.message.trim() === "") {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // employee ************************

  const [formDatas, setFormDatas] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    message: "",
  });

  const [errorss, setErrorss] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    message: "",
  });

  const handleSubmits = async () => {
    // Validate the form fields before submitting
    if (validateForms()) {
      try {
        const response = await fetch("https://admin.zensei.in/api/candidate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDatas),
        });

        if (response.ok) {
          // Request was successful, you can handle the response here
          alert("Your Form send Successfully");
          console.log(formDatas, "Request successful");
          setFormDatas({
            name: "",
            email: "",
            phone: "",
            role: "",
            message: "",
          });
        } else {
          // Request failed, handle the error here
          console.error("Request failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateForms = () => {
    let valid = true;
    const newErrorss = {
      name: "",
      email: "",
      phone: "",
      role: "",
      message: "",
    };

    if (formDatas.name.trim() === "") {
      newErrorss.name = "Name is required";
      valid = false;
    }

    if (formDatas.email.trim() === "") {
      newErrorss.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formDatas.email)) {
      newErrorss.email = "Email is not in a valid format";
      valid = false;
    }

    if (formDatas.phone.trim() === "") {
      newErrorss.phone = "Phone number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formDatas.phone)) {
      newErrorss.phone = "Phone number must be 10 digits";
      valid = false;
    }

    if (formDatas.role.trim() === "") {
      newErrorss.role = "Role is required";
      valid = false;
    }

    if (formDatas.message.trim() === "") {
      newErrorss.message = "Message is required";
      valid = false;
    }

    setErrorss(newErrorss);
    return valid;
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormDatas({ ...formDatas, [name]: value });
  };

  return (
    <>
      <div className="container">
        <div className="contact-us">
          <h1> Let's Connect and Create History</h1>
          <h4>Get Your SaaS vision the push it needs</h4>
          <div className="contact-wrapper">
            <div className="contact-wrapper-box">
              <HiOutlineLocationMarker />
              <h6>Office Address</h6>
              <p className="para-black">
                <address>
                  {" "}
                  Address: 2nd floor, 1142, 6th Main Rd, near Star Bazaar, Sector 7, HSR Layout, Bengaluru, Karnataka 560102
                </address>
              </p>
            </div>
            <div className="contact-wrapper-box">
              <HiOutlineMail />
              <h6>Send Email</h6>
              <p className="para-black">
                <a href="mailto:info@zensei.in">info@zensei.in</a>
              </p>
            </div>
            <div className="contact-wrapper-box">
              <BsTelephoneForward />
              <h6>Contact Number</h6>
              <p className="para-black">
                <a href="tel:+91 9066823853">9066823853</a>
              </p>
            </div>
          </div>
          <div className="contact-box-wrapper">
            <div className="contact-tabs">
              <Tabs>
                <TabList>
                  <Tab>I Represent a Company</Tab>
                  <Tab>I am a Candidate</Tab>
                </TabList>

                <TabPanel>
                  <div className="contact-tabs-form">
                    <h2>Send Us Your Question!</h2>
                    <div className="form">
                      <div className="form-wrapper">
                        <div>
                          <label>Name*</label>
                          <input
                            name="name"
                            placeholder=""
                            type="text"
                            onChange={handleChange}
                            value={formData.name}
                          />
                          <span className="error">{errors.name}</span>
                        </div>
                        <div>
                          <label>Email Address*</label>
                          <input
                            name="email"
                            placeholder=""
                            type="text"
                            onChange={handleChange}
                            value={formData.email}
                          />
                          <span className="error">{errors.email}</span>
                        </div>
                        <div>
                          <label>Contact Number*</label>
                          <input
                            name="phone"
                            placeholder=""
                            type="text"
                            onChange={handleChange}
                            value={formData.phone}
                          />
                          <span className="error">{errors.phone}</span>
                        </div>
                        <div>
                          <label>Role/s you are hiring for*</label>
                          <input
                            name="role"
                            placeholder=""
                            type="text"
                            onChange={handleChange}
                            value={formData.role}
                          />
                          <span className="error">{errors.role}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-textarea">
                      <label>Message*</label>
                      <textarea
                        name="message"
                        rows="4"
                        onChange={handleChange}
                        value={formData.message}
                      />
                      <span className="error">{errors.message}</span>
                    </div>
                    <button className="btn-submit" onClick={handleSubmit}>
                      SUBMIT
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="contact-tabs-form">
                    <h2>I am a Candidate!</h2>
                    <div className="form">
                      <div className="form">
                        <div className="form-wrapper">
                          <div>
                            <label>Name*</label>
                            <input
                              name="name"
                              placeholder=""
                              type="text"
                              onChange={handleChanges}
                              value={formDatas.name}
                            />
                            <span className="error">{errorss.name}</span>
                          </div>
                          <div>
                            <label>Email Address*</label>
                            <input
                              name="email"
                              placeholder=""
                              type="text"
                              onChange={handleChanges}
                              value={formDatas.email}
                            />
                            <span className="error">{errorss.email}</span>
                          </div>
                          <div>
                            <label>Contact Number*</label>
                            <input
                              name="phone"
                              placeholder=""
                              type="text"
                              onChange={handleChanges}
                              value={formDatas.phone}
                            />
                            <span className="error">{errorss.phone}</span>
                          </div>
                          <div>
                            <label>Role you are applying for*</label>
                            <input
                              name="role"
                              placeholder=""
                              type="text"
                              onChange={handleChanges}
                              value={formDatas.role}
                            />
                            <span className="error">{errorss.role}</span>
                          </div>
                        </div>
                        <div className="form-textarea">
                          <label>Message*</label>
                          <textarea
                            name="message"
                            rows="4"
                            onChange={handleChanges}
                            value={formDatas.message}
                          />
                          <span className="error">{errorss.message}</span>
                        </div>
                        <button className="btn-submit" onClick={handleSubmits}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
