import React from "react";
import imgf from "../../images/imagef.png";
import imgs from "../../images/images.png";
import imgt from "../../images/imaget.png";
import { Link } from "react-router-dom";

const HomeSaas = () => {
  return (
    <>
      <div className="container">
        <div className="homesaas">
          <div className="homesaas-vision">
            <h1>
              <span>
                Acing SaaS Tech <br />
                Hiring&nbsp;
              </span>
              is a Convoluted <br /> Process
            </h1>
            <h6>
              <span>We untangle the mystery for you </span>with a process design
              to fuel your vision with the talent that stands between your
              company's and aspired state
            </h6>
            <Link to="/contact">
              <button>Realise your SaaS Vision</button>
            </Link>
          </div>

          <div className="homesaas-vision-image">
            <div className="homesaas-vision-image-left">
              <div className="img-first">
                <img src={imgf} alt="" />
              </div>
              <div className="img-second">
                <img src={imgs} alt="" />
              </div>
            </div>
            <div className="homesaas-vision-image-right">
              <div className=" homesaas-vision-image-right-box">
                <img src={imgt} alt="" />

                {/* <div className="homesaas-vision-image-right-subs">
                  <h2>479%</h2>
                  <p>
                    Subscribers <br /> Increase
                  </p>
                </div>
                <div className="homesaas-vision-image-right-powers">
                  <h2>---</h2>
                  <p>
                    Powered by the new <br /> Tech Team
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSaas;
