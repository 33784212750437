import React from "react";
import ReactStars from "react-stars";
import medisage from "../../images/medisage.png";
import workhack from "../../images/work.png";
import zen from "../../images/zen.png";
import bess from "../../images/bess.jpeg";
import deep from "../../images/deep.png";
import urban from "../../images/urban.jpeg";

const TestomonialCard = () => {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  return (
    <>
      <div className="container">
        <div className="TestomonialCard">
          <div className="TestomonialCard-wrapper">
            <img src={bess} alt="" />
            <div className="testomonial-rating">
              <h2>Deepa Papanna</h2>
              <p>(VP Talent - Bessemer Venture Capital)</p>
              <div className="rating-point">
                <ReactStars
                  count={5}
                  value={5}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                  Zensei has proven to be a great hiring partner for our
                  early-stage companies. They have helped some of our partners
                  in setting up initial teams as well as hire engineering
                  leaders. Their ability to understand the founder's mind set and
                  curate the right talent, and with speed has worked very well
                  for us.
                </p>
              </div>
            </div>
          </div>
          <div className="TestomonialCard-wrapper">
            <img src={medisage} alt="" />
            <div className="testomonial-rating">
              <h2>Anurag Dhingra</h2>
              <p>(Co-Founder - Medisage)</p>
              <div className="rating-point">
                <ReactStars
                  count={5}
                  value={4}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                  Zensei has been a good partner in helping us close our
                  position of head of Engineering. The kind of CVs that were the
                  team had done early diligence to understand both intent and
                  caliber of the candidate. Within 2-3 candidates, we were able to
                  close the position saving a ton of time.
                </p>
              </div>
            </div>
          </div>
          <div className="TestomonialCard-wrapper">
            <img src={workhack} alt="" />
            <div className="testomonial-rating">
              <h2>Akshat Tyagi</h2>
              <p>(Founder - Workhack)</p>

              <div className="rating-point">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  value={4.5}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                  Zensei has been super helpful in finding us our dream
                  engineering team. We've tried a bunch of agencies but only
                  Zensei made sure the shortlist given to us was worth spending
                  time on. Parmesh calls you up after every candidates
                  chat, listens to the feedback and adapts very quickly. We think
                  of Zensei as our in-house HR team.
                </p>
              </div>
            </div>
          </div>
          <div className="TestomonialCard-wrapper">
            <img src={zen} alt="" />
            <div className="testomonial-rating">
              <h2>Apurv Bansal</h2>
              <p>(Founder - Zenskar)</p>
              <div className="rating-point">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  value={5}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                  Their passion for the industry and their professionalism and
                  acumen ensure that you find the right talent in a short
                  period. The team has been great throughout the hiring
                  journey, taking time to really understand not just the role,
                  but our culture and the type of person who would be a fit - The
                  level of attention to detail has been impressive.
                </p>
              </div>
            </div>
          </div>
          <div className="TestomonialCard-wrapper">
            <img src={deep} alt="" />
            <div className="testomonial-rating">
              <h2>Indrajeet Deshmukh</h2>
              <p>(Senior Recruitment Specialists- Deepintent)</p>
              <div className="rating-point">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  value={4.5}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                It's been a great journey working with your team! Recruiting for tech roles was made easy by your exceptional support. The candidates you provide and your pipeline are spot-on, making our interview process highly productive. Also, the POCs are responsive, keeping communication smooth. Excited to fill current roles fast and take on more in the future!
                </p>
              </div>
            </div>
          </div>
          <div className="TestomonialCard-wrapper">
            <img src={urban} alt="" />
            <div className="testomonial-rating">
              <h2>Purnendu Shakunt</h2>
              <p>(Senior Manager Talent Acquisition- UrbanPiper)</p>
              <div className="rating-point">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  edit={false}
                  value={5}
                  color2={"#ffd700"}
                />
              </div>
              <div className="testomonial-para">
                <p>
                I would assign a rating of 5 out of 5 in terms of the proactive approach and meticulous attention to detail demonstrated when tackling intricate technical requirements. Additionally, the commitment to thorough follow-up to refine search parameters, coupled with a genuine dedication to successfully fulfilling the requirements, is particularly noteworthy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestomonialCard;
