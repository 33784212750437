import React from "react";

const BottomBar = () => {
  return (
    <>
        <div className="bottom-bar-bg">

      <div className="container bottom-bar">
          <div className="bottom-bar-left">
            <p>
              <span>#</span> All Right Reserved
            </p>
          </div>
          <div className="bottom-bar-right">
            <h6> @ 2023 Zensei Labs Search Pvt Ltd</h6>
            <p className="para-white">Proudly Made in India</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomBar;
