import React from "react";
import mission from '../../images/mission.png'
import vission from '../../images/vission.png'

const AboutPhilospy = () => {
  return (
    <>
      <div className="about-philospy">
        <div className="container">
          <div className="about-philospy-wrapper">
            <h1>Our Philosophy</h1>
            <div className="about-philosophy-content">
              <div>
                <img
                  src={vission}
                  alt=""
                />
                <h2>Vision</h2>
                <p>
                  TO BE "The No. 1" Global Search Partner for SaaS companies
                </p>
              </div>
              <div>
                <img
                  src={mission}
                  alt=""
                />
                <h2>Mission</h2>
                <p>
                 "We empower SaaS companies by Connect, Engage, & Scale, technology talent"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPhilospy;
