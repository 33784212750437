import React, { useEffect } from 'react'
import Testimonial from './TestimonialHead'
import TestomonialCard from './TestomonialCard'

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);
  return (
    <>
    <div className='bg-gray'>
    <Testimonial/>
    <TestomonialCard/>
    </div>
    </>
  )
}

export default Testimonials