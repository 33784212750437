import React from "react";

const BlogHead = () => {
  return (
    <div className="container">
      <div className="blog-head">
        <h2>Zensei Blogs</h2>
        <h1>Discover, execute and ascend</h1>
        <p>
          Keep up with SaaS news, hiring trends, our success stories and more
        </p>
      </div>
    </div>
  );
};

export default BlogHead;
