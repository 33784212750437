import React from "react";
import person from "../../images/aboutman.jpg";
import { Link } from "react-router-dom";

const AboutExperience = () => {
  return (
    <>
      <div className="about-experience">
        <div className="container">
          <div className="home-experience">
            <div className="home-experience-image">
              <img src={person} className="imge" alt="" />
              <div className="image-details">
                <div className="image-details-left">
                  <img src={person} alt="" width={30} />
                </div>
                <div className="image-details-center">
                  <h5>SaaS Tech</h5>
                  <h6>Ron</h6>
                </div>
                <div className="image-details-right">
                  <h6>Hired</h6>
                </div>
              </div>
            </div>
            <div className="home-experience-text">
              <h1>
                Incorporated to solve <br /> SaaS specific challenges
              </h1>
              <p>
              Zensei was founded by Vikram S and Parmesh Sharma after years of
                experiencing and solving hiring challenges for SaaS Tech
              </p>
              <div className="home-experience-text-box">
                <div>
                  <h4>
                    SaaS is <br /> different!
                  </h4>
                  <p>
                    Both the founders first - handedly experienced that SaaS
                    hiring challenges are exclusive and no one in the industry
                    was solving them.
                  </p>
                </div>
                <div>
                  <h4>
                    15+ Years <br /> each
                  </h4>
                  <p>
                    Both the founders have 15+ years of collective experience in
                    helping medium and large corporations disrupt sectors with
                    exceptional talent acquisitions.
                  </p>
                </div>
              </div>
              <Link to="/contact">
                <button className="button1">Experience our approach</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutExperience;
