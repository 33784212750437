import React from "react";
import ReactStars from "react-stars";
import medisage from "../../images/medisage.png";
import bess from "../../images/bess.jpeg";
import { Link } from "react-router-dom";

const HomeTestomonials = () => {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <>
      <div className="home_f">
        <div className="container ">
          <div className="home-testomonials">
            <div className="home-testomonials-left">
              <h1>
                Here's what SaaS disruptors have <br />
                to say about us
              </h1>
              <p>
              We've customized our techniques and procedures for SaaS <br/> ventures. They're aimed at overcoming the obstacles common to every SaaS startup.
              </p>
            </div>
            <div className="TestomonialCard">
              <div className="TestomonialCard-wrapper">
                <img src={bess} alt="" />
                <div className="testomonial-rating">
                  <h2>Deepa Papanna</h2>
                  <p>(VP Talent - Bessemer Venture Capital)</p>
                  <div className="rating-point">
                    <ReactStars
                      count={5}
                      value={5}
                      onChange={ratingChanged}
                      size={40}
                      edit={false}
                      color2={"#ffd700"}
                    />
                  </div>
                  <div className="testomonial-para">
                    <p>
                      Zensei has proven to be a great hiring partner for our
                      early-stage companies. They have helped some of our partners in setting up initial teams as well as hire
                      engineering leaders. Their ability to understand the
                      founder's mind set and curate...{" "}
                      <Link to="/testimonial">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="TestomonialCard-wrapper">
                <img src={medisage} alt="" />
                <div className="testomonial-rating">
                  <h2>Anurag Dhingra</h2>
                  <p>(Co-Founder - Medisage)</p>
                  <div className="rating-point">
                    <ReactStars
                      count={5}
                      value={4}
                      onChange={ratingChanged}
                      size={40}
                      edit={false}
                      color2={"#ffd700"}
                    />
                  </div>
                  <div className="testomonial-para">
                    <p>
                      Zensei has been a good partner in helping us close our
                      position of head of Engineering. The kind of CVs that were
                      the team had done early diligence to understand both
                      intent and caliber of the candidate. Within...
                      <Link to="/testimonial">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTestomonials;
