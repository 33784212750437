import React from "react";
import cart from "../../images/cart.png";
import cartsec from "../../images/cartsec.png";
import cardth from "../../images/cardth.png";
import cardfor from "../../images/cardfor.png";
import { Link } from "react-router-dom";

const HomeServices = () => {
  return (
    <>
      <div className="container">
        <div className="home-service">
          <h1>
            Service designed for <br /> <span>SaaS Domination</span>
          </h1>
          <div className="home-service-wraper">
            <div className="home-service-card">
              <img src={cart} alt="" />
              <h3>Mid and Senior SaaS Tech Hiring</h3>
              <p>Build teams that are ready to disrupt and scale</p>
              <Link to="/service#section1">
                <button>Know More</button>
              </Link>
            </div>
            <div className="home-service-card">
              <img src={cartsec} alt="" />
              <h3>Leadership SaaS Tech Hiring</h3>
              <p>
                Onboard visionary leadership with skill sets ready to create
                history
              </p>
              <Link to="/service#section2">
                <button>Know More</button>
              </Link>
            </div>
            <div className="home-service-card">
              <img src={cardth} alt="" />
              <h3>Diversity Hiring</h3>
              <p>
                Realise your vision’s true potential with the right mix of
                talent, free from all biases
              </p>
              <Link to="/service#section3">
                <button>Know More</button>
              </Link>
            </div>
            <div className="home-service-card">
              <img src={cardfor} alt="" />
              <h3>Customized SaaS Tech Hiring</h3>
              <p>
                History is never created with one-size-fits-all packages.
                Explore our boutique offerings
              </p>
              <Link to="/service#section4">
                <button>Know More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices;
