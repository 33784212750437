import React, { useEffect } from "react";
import ServiceBanner from "./ServiceBanner";
import ServiceExperience from "./ServiceExperience";
import ServiceLeadership from "./ServiceLeadership";
import ServicePower from "./ServicePower";
import ServiceBoutique from "./ServiceBoutique ";
import ServiceHiring from "./serviceHiring";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <div>
        <div className="home_f">
          <ServiceBanner />
        </div>
        <section id="section1">
          <div className="home_f">
            <ServiceExperience />
          </div>
        </section>
        <section id="section2">
          <div className="home_f">
            <ServiceLeadership />
          </div>
        </section>
        <section id="section3">
          <ServiceHiring />
        </section>
        <section id="section4">
          <div className="home_f">
            <ServiceBoutique />
            <ServicePower />
          </div>
        </section>
      </div>
    </>
  );
};

export default Service;
