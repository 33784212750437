import React from "react";
import person from "../../images/perosn.png";
import { Link } from "react-router-dom";

const HomeExperience = () => {
  return (
    <>
      <div className="container">
        <div className="home-experience">
          <div className="home-experience-image">
            <img src={person} className="imge" alt="" />
            <div className="image-details">
              <div className="image-details-left">
                <img src={person} alt="" width={30} />
              </div>
              <div className="image-details-center">
                <h5>SaaS Tech</h5>
                <h6>Rahul Kumar</h6>
              </div>
              <div className="image-details-right">
                <h6>Hired</h6>
              </div>
            </div>
          </div>
          <div className="home-experience-text">
            <h1>
              Experience the  <br />
             'Zensei Advantage'
            </h1>
            <p>
              With countless crucial factors playing their role at the same
              time, your hiring needs a process perfected over decades and bound
              to succeed.
            </p>
            <div className="home-experience-text-box">
              <div>
                <h4>
                  Designed for <br /> excellence
                </h4>
                <p>
                We blend behavioral patterns, technology usage, past experiences, and more than 17 other elements in our approach.
                </p>
              </div>
              <div>
                <h4>
                  Times and process <br /> driven
                </h4>
                <p>
                Our approach and methods make sure each phase is excellent at evaluation, while still keeping on track with the time goals.
                </p>
              </div>
            </div>
            <Link to="/contact">
              <button className="button1">Experience our approach</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeExperience;
