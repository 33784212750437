import React from "react";
import handshake from "../../images/handshake.png";
import big from "../../images/big.png";
import own from "../../images/own.png";
import win from "../../images/win.png";
import respect from "../../images/respect.png";

const OurValues = () => {
  return (
    <>
      <div className="container">
        <div className="our-value-wrapper">
          <div className="about-philospy-wrapper">
            <h1>Our Values</h1>
          </div>
          <div className="our-value-top">
            <div className="our-value-top-box">
              <img
                src={respect}
                alt=""
              />
              <h2>RESPECT</h2>
              <p>
                Encourage ideas which<br/> make it easier for us to<br/> achieve our
                common goals.
              </p>
            </div>
            <div className="our-value-top-box">
              <img src={handshake} alt="" />
              <h2>PASSION</h2>
              <p>Purpose!! We love WHAT we<br/> do, and WHY we do it!</p>
            </div>
            <div className="our-value-top-box">
              <img
                src={own}
                alt=""
              />
              <h2>OWN</h2>
              <p>
                We take full ownership of the<br/> outcome of our actions, not<br/> just
                the completion of the<br/> output.
              </p>
            </div>
          </div>
          <div className="our-value-bottom">
            <div className="our-value-top-box">
              <img
                src={big}
                alt=""
              />
              <h2>THINK BIG</h2>
              <p>
                We never settle - In everything we do, we<br/> challenge ourselves
                and exceed the<br/> expectations of our customers.
              </p>
            </div>
            <div className="our-value-top-box">
              <img
                src={win}
                alt=""
              />
              <h2>WIN</h2>
              <p>We play to win together. </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurValues;
