import React from "react";
import bountique from "../../images/Mask-group.png";
import rightarrow from "../../images/right-arrow.png";
import cup from "../../images/cup.png";
import { Link } from "react-router-dom";

const ServiceHiring = () => {
  return (
    <>
      <div className="container">
        <div className="service-boutique">
          <div className="service-boutique-left hiring">
            <img src={bountique} alt="" />
            <div className=" cup-box">
              <h4>Winner #1</h4>
              <div className="cup-box-wrapper">
                <img src={cup} alt="" />
                <p>
                  Most downloaded <br />
                  software for 2024
                </p>
              </div>
            </div>
          </div>
          <div className="service-boutique-left">
            <h5>SaaS Domination</h5>
            <h1>Diversity Hiring</h1>
            <p>
              Expand your team’s talent horizons and boost creativity with our
              diversity-driven hiring solutions. Achieve higher profitability
              with a more inclusive team.
            </p>
            <ul>
              <li>
                <img src={rightarrow} alt="" /> Pick from a diverse talent pool
              </li>
              <li>
                <img src={rightarrow} alt="" /> Embrace diversity across ethnicities, age, gender and more
              </li>
              <li>
                <img src={rightarrow} alt="" />
                Cultural fit assessment
              </li>
            </ul>
            <Link to="/contact"><button className="button1">Get Started</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHiring;
