import React, { useEffect } from "react";
import BlogHead from "./BlogHead";
import BlogCard from "./BlogCard";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);
  return (
    <>
      <BlogHead />
      <BlogCard />
    </>
  );
};

export default Blog;
