import React from "react";
import roket from "../../images/roket.png";
import kite from "../../images/kite.png";
import { Link } from "react-router-dom";

const HomeJourney = () => {
  return (
    <>
      <div className="container">
        <div className="home-journey">
          <h1>
            India's&nbsp;<span>
              First SaaS only <br /> Tech Hiring&nbsp;</span>
            Search Firm
          </h1>
          <h5>
            Empowering <span> SaaS Companies</span> with Top Notch Tech Talents
          </h5>
          <p>
            Unlike other domains. Tech Hiring in SaaS is Unconventional. The
            Synergy between the organization’s vision, Ideology, culture and
            umpteen other aspects and the “Dream Tech Team” can have a
            monumental impact on the organization’s future.
          </p>
          <p>
          With a decade of dedication, we've fine-tuned our ability to identify top-notch tech teams. Drawing from our niche specialization and adaptable strategies, we're poised to usher you toward victory. Excited to explore how we can catalyze your success? Keep reading!
          </p>

          <Link to="/contact">
            <button className="button1">
              Start your <span> JOURNEY </span>
            </button>
          </Link>
          <div className="roket">
            <img src={roket} alt="" />
          </div>
          <div className="kite">
            <img src={kite} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeJourney;
