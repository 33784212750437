import React, { useEffect, useState } from "react";
import { BsArrowDownRight } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";

const BlogListCategory = () => {
  const { category } = useParams();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch data based on the category parameter
    fetch(`https://admin.zensei.in/api/blogs/category/${category}`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming that the data structure matches your API response
        setBlogs(data.blogs);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [category]);

  return (
    <>
      <div className="container">
        <div className="bloglistcategory">
          <h2 className="capital">{category}</h2>
          <Link to="/blogs">
            <button className="blog-button">← Back to all blog articles</button>
          </Link>
        </div>
        <div className="bloglistcategory-wrapper">
          <div className="blog-multiple-card">
            {blogs.map((blog) => (
              <div key={blog.id} className="blog-multiple-card-wrapper">
                <div className="blog-multiple-card-wrapper-img">
                  <Link to={`/blog-details/${blog.id}`}>
                    {blog.details && blog.details[0] && (
                      <img
                        src={`${"https://admin.zensei.in/"}${
                          blog.details[0].images
                        }`}
                        alt=""
                      />
                    )}
                  </Link>
                </div>

                <div className="blog-multiple-card-wrapper-content">
                  <h6 className="capital">{blog.category}</h6>
                  <Link to={`/blog-details/${blog.id}`}>
                    <div>
                      <h3>{blog.heading}</h3>
                      <span>
                        <BsArrowDownRight />
                      </span>
                    </div>
                  </Link>
                  {blog.details && blog.details[0] && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: limitTextTo23Words(blog.details[0].paragraph),
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const limitTextTo23Words = (text) => {
  const words = text.split(" ");
  const limitedWords = words.slice(0, 23);
  return limitedWords.join(" ");
};

export default BlogListCategory;
