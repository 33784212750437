import React from "react";

import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer">
            <div>
              <h4>
                Ready to disrupt?
                <br />
                Let's Talk
              </h4>
            </div>
            <div className="footer-resposive">
              <div>
                <ul>
                  Company
                  <li>
                    <Link to="/"> Home</Link>
                  </li>
                  <li>
                    <Link to="/about"> About us</Link>
                  </li>
                  <li>
                    <Link to="/service"> Services</Link>
                  </li>
                  {/* <li>Verticals</li> */}
                  <Link to="testimonial">
                    <li>Testimonials</li>
                  </Link>
                  <Link to="/blogs">
                    <li>Blogs</li>
                  </Link>
                </ul>
              </div>

              <div>
                <ul>
                  QUICK LINKS
                  <li>
                    <Link to="/contact"> Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy"> Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions"> Terms and Condition</Link>
                  </li>
                  {/* <li>Careers</li> */}
                </ul>
              </div>
              <div className="only-ph"></div>
              <div className="icons-footer">
                <a href="https://twitter.com/LabsZensei67610">
                  <BsTwitter />
                </a>
                <a href="https://www.instagram.com/zenseilabs/?igshid=MzRlODBiNWFlZA%3D%3D">
                  <BsInstagram />
                </a>
                <a href="https://in.linkedin.com/company/zensei-labs-search-pvt-ltd">
                  <BsLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
