import React from "react";
import { BsLinkedin } from "react-icons/bs";
// import vikram from "../../images/vikram.png";
import pramesh from "../../images/pramesh.png";

const AboutTesto = () => {
  return (
    <>
      <div className="about-testo">
        <div className="container">
          <div className="about-philospy-wrapper">
            <h1>Meet the Brains Behind Zensei </h1>
            <div className="about-testo-content">
              {/* <div className="pramesh">
                <img src={vikram} alt="" />
                <div className="about-testo-subcontent">
                  <h3>Vikram S Mansabdar</h3>
                  <h6>Founder and CEO</h6>
                  <div className="about-testo-subcontent-socials">
                    <p>
                      Let's connect on
                      <a
                        href="https://www.linkedin.com/in/vikrammansabdar/"
                        target="blank"
                      >
                        <BsLinkedin />
                      </a>
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="vikram">
                <img src={pramesh} alt="" />
                <div className="about-testo-subcontent">
                  <h3>Parmesh Sharma</h3>
                  <h6>Founder & CEO </h6>
                  <div className="about-testo-subcontent-socials">
                    <p>
                      Let's connect on{" "}
                      <a
                        href="https://www.linkedin.com/in/parmesh-sharma-651b6181/"
                        target="blank"
                      >
                        {" "}
                        <BsLinkedin />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTesto;
